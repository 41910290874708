import {
  cookieStorage,
  makePersisted,
  storageSync,
} from "@solid-primitives/storage";
import { Accessor, Setter, createSignal } from "solid-js";
import { Store, createStore } from "solid-js/store";

export type StoredCustomer = {
  is_loaded: boolean;
  first_name: string;
  last_name: string;
  user_id: string;
  email: string;
  country_code: string;
};
function createCustomerStore(): [StoredCustomer, Setter<StoredCustomer>] {
  return makePersisted(
    createStore<StoredCustomer>({
      is_loaded: false,
      first_name: "",
      last_name: "",
      user_id: "",
      email: "",
      country_code: "",
    }),
    {
      storage: cookieStorage,
    }
  );
}

export const customerStore: [StoredCustomer, Setter<StoredCustomer>] =
  createCustomerStore();
